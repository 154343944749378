import { Action } from '@ngrx/store';
import { Organization, Quota } from '@neuralegion/api';

export interface UserOrganizationState {
  entity: Organization | null;
  quotas: Quota[];
  error: string | null;
  pending: Action[];
}

export const initialUserOrganizationState: UserOrganizationState = {
  entity: null,
  quotas: [],
  error: null,
  pending: []
};
