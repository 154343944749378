import { Injectable } from '@angular/core';
import { Observable, exhaustMap, filter, map, take, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { trackEvent } from '@neuralegion/analytics-api';
import { Organization, Scope } from '@neuralegion/api';
import { Role, selectRoles } from '@neuralegion/roles-lookup-api';
import { addGroup } from './groups.actions';
import { addMember } from './members.actions';
import { updateOrganization } from './organization.actions';
import { selectOrganization } from './organization.selectors';
import { addRole } from './roles.actions';

@Injectable()
export class OrganizationAnalyticsEffects {
  public readonly trackUpdateOrganizationName$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganization),
      withLatestFrom(this.store.select(selectOrganization)),
      filter(
        ([action, organization]: [ReturnType<typeof updateOrganization>, Organization]) =>
          action.payload.organization.name !== organization.name
      ),
      map(([action, organization]: [ReturnType<typeof updateOrganization>, Organization]) =>
        trackEvent({
          name: 'Update Organization Form Submitted',
          properties: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            org_id: organization.id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            org_name: action.payload.organization.name
          }
        })
      )
    )
  );

  public readonly trackAddRole$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addRole),
      map((action: ReturnType<typeof addRole>) =>
        trackEvent({
          name: 'Create Role Form Submitted',
          properties: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            role_name: action.payload.role.name,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            role_allowed_scopes: action.payload.role.scopes
          }
        })
      )
    )
  );

  public readonly trackAddGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addGroup),
      exhaustMap((action: ReturnType<typeof addGroup>) =>
        this.store.select(selectRoles([action.payload.group.roleId])).pipe(
          map((roles: Role[]): [ReturnType<typeof addGroup>, Scope[]] => [
            action,
            roles?.[0]?.scopes ?? []
          ]),
          take(1)
        )
      ),
      map(([action, roleScopes]: [ReturnType<typeof addGroup>, Scope[]]) =>
        trackEvent({
          name: 'Create Group Form Submitted',
          properties: {
            /* eslint-disable @typescript-eslint/naming-convention */
            group_name: action.payload.group.name,
            group_description: action.payload.group.description,
            role_id: action.payload.group.roleId,
            role_allowed_scopes: roleScopes,
            group_members: action.payload.group.members
            /* eslint-enable @typescript-eslint/naming-convention */
          }
        })
      )
    )
  );

  public readonly trackAddMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addMember),
      map((action: ReturnType<typeof addMember>) =>
        trackEvent({
          name: 'Invite Member Form Submitted',
          properties: {
            /* eslint-disable @typescript-eslint/naming-convention */
            invitation_full_name: action.payload.invitation.name,
            invitation_email: action.payload.invitation.email,
            invitation_role_id: action.payload.invitation.roleId,
            invitation_groups: action.payload.invitation.groups
            /* eslint-enable @typescript-eslint/naming-convention */
          }
        })
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store
  ) {}
}
