import { HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { ChangedValue, Headers, MimeType } from '@neuralegion/api';
import { FileService } from '@neuralegion/core';
import { Code } from '../../services';

export type BodyPayload = { headers?: Headers; body?: string; bodyUrl?: string };

@Component({
  selector: 'share-body-code-viewer',
  templateUrl: './body-code-viewer.component.html',
  styleUrls: ['./body-code-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyCodeViewerComponent implements OnInit, OnDestroy {
  @Input()
  public payload$: Observable<ChangedValue<BodyPayload>>;

  @Input()
  public payloadType = '';

  @Input()
  public filename = 'data.bin';

  private readonly thresholdBodyAutoShow = 10 * 1024;
  public readonly thresholdBodyTooLarge = 1024 * 1024;

  public code$: Observable<Code>;
  private readonly gc = new Subject<void>();
  public readonly bodyShownSubject = new BehaviorSubject<boolean>(false);

  constructor(private readonly fileService: FileService) {}

  public ngOnInit(): void {
    this.code$ = combineLatest([this.bodyShownSubject, this.payload$]).pipe(
      distinctUntilChanged(equal),
      map(
        ([shown, payload]: [boolean, ChangedValue<BodyPayload>]): Code =>
          shown
            ? {
                actual: payload.actual.body,
                previous: payload.previous?.body,
                language: payload.actual.headers
              }
            : null
      )
    );

    this.payload$
      .pipe(
        map(
          (value) =>
            Math.max(
              ...[value.actual, value.previous].map((payload: BodyPayload): number =>
                this.getBodySize(payload)
              )
            ) < this.thresholdBodyAutoShow
        ),
        distinctUntilChanged(),
        takeUntil(this.gc)
      )
      .subscribe((shown: boolean) => {
        this.bodyShownSubject.next(shown);
      });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public getBodySize<T extends BodyPayload>(payload: T): number {
    if (!payload || !payload.body) {
      return 0;
    }

    const contentLengthStr: string = new HttpHeaders(payload.headers).get('Content-Length');
    if (contentLengthStr) {
      return +contentLengthStr;
    }

    const blob = new Blob([payload.body]);
    return blob.size;
  }

  public downloadBody(body: string): void {
    this.fileService.saveFile({
      data: body,
      mimeType: MimeType.BINARY,
      name: this.filename
    });
  }

  public showBody(): void {
    this.bodyShownSubject.next(true);
  }
}
