import { createAction } from '@ngrx/store';
import { Role } from '../models';

export const loadRoles = createAction(
  '[RolesLookup] LOAD_ROLES_BY_IDS',
  (payload: string[], orgId?: string, force = false) => ({ payload, orgId, force })
);

export const loadRolesSuccess = createAction(
  '[RolesLookup] LOAD_ROLES_BY_IDS_SUCCESS',
  (payload: Role[]) => ({ payload, parentType: loadRoles.type })
);

export const loadRolesFail = createAction(
  '[RolesLookup] LOAD_ROLES_BY_IDS_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadRoles.type
  })
);

export const resetRoles = createAction('[RolesLookup] RESET_ROLES');
