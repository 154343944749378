import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, mergeMap, of, take, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import { Scope } from '@neuralegion/api';
import {
  selectOwnOrganizationId,
  selectScopePermission,
  selectUserScopes
} from '@neuralegion/auth-api';
import { Role } from '../models';
import { RolesLookupService } from '../services';
import { loadRoles, loadRolesFail, loadRolesSuccess } from './roles-lookup.actions';
import { selectRoleEntities } from './roles-lookup.selectors';

@Injectable()
export class RolesLookupEffects {
  public readonly loadRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRoles),
      mergeMap((action: ReturnType<typeof loadRoles>) => {
        const ids = Array.from(new Set(action.payload));

        return action.force
          ? of(ids)
          : this.store.select(selectRoleEntities).pipe(
              take(1),
              map((dictionary: Dictionary<Role>) => ids.filter((id) => !dictionary[id]))
            );
      }),
      withLatestFrom(
        this.store.select(selectScopePermission(Scope.ROLES_READ)),
        this.store.select(selectOwnOrganizationId),
        this.store.select(selectUserScopes)
      ),
      mergeMap(
        ([ids, rolesReadPermission, ordId, scopes]: [
          string[],
          boolean,
          string,
          ReadonlySet<Scope>
        ]) => {
          return rolesReadPermission && ids.length
            ? this.rolesLookupService.loadRolesByIds(ids, ordId).pipe(
                map((res: Role[]) =>
                  loadRolesSuccess(
                    res.map((r) => ({
                      ...r,
                      allowed: r.scopes.every((scope: Scope) => scopes.has(scope))
                    }))
                  )
                ),
                catchError((err: HttpErrorResponse) => of(loadRolesFail(err.error)))
              )
            : of(loadRolesSuccess([]));
        }
      )
    )
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly rolesLookupService: RolesLookupService
  ) {}
}
