import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthProvidersApiModule } from '@neuralegion/auth-providers-api';
import { IntegrationsApiModule } from '@neuralegion/integrations-api';
import { CodeInstrumentationGuard } from '../guards';
import { GroupsService, OrganizationService, RolesService } from '../services';
import { GroupsV1Effects } from '../store/groups-v1.effects';
import { groupsV1Reducer } from '../store/groups-v1.reducer';
import { GroupsEffects } from '../store/groups.effects';
import { groupsReducer } from '../store/groups.reducer';
import { MembersEffects } from '../store/members.effects';
import { membersReducer } from '../store/members.reducer';
import { OrganizationAnalyticsEffects } from '../store/organization-analytics.effects';
import { OrganizationEffects } from '../store/organization.effects';
import { organizationReducer } from '../store/organization.reducer';
import { RolesEffects } from '../store/roles.effects';
import { rolesReducer } from '../store/roles.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('groups-v1', groupsV1Reducer),
    StoreModule.forFeature('groups', groupsReducer),
    StoreModule.forFeature('members', membersReducer),
    StoreModule.forFeature('organization', organizationReducer),
    StoreModule.forFeature('roles', rolesReducer),

    EffectsModule.forFeature([
      GroupsEffects,
      GroupsV1Effects,
      MembersEffects,
      OrganizationAnalyticsEffects,
      OrganizationEffects,
      RolesEffects
    ]),

    AuthProvidersApiModule,
    IntegrationsApiModule
  ],
  providers: [CodeInstrumentationGuard, GroupsService, OrganizationService, RolesService]
})
export class OrganizationApiModule {}
