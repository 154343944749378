import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RolesLookupService } from '../services';
import { RolesLookupEffects } from '../store/roles-lookup.effects';
import { rolesLookupReducer } from '../store/roles-lookup.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('rolesLookup', rolesLookupReducer),
    EffectsModule.forFeature([RolesLookupEffects])
  ],
  providers: [RolesLookupService],
  exports: []
})
export class RolesLookupApiModule {}
