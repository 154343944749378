import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Role } from '../models';

export interface RolesLookupState extends EntityState<Role> {
  readonly pending: Action[];
  readonly error: string | null;
}

export const rolesLookupEntityAdapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  selectId: (model) => model.id
});

export const rolesLookupInitialState: RolesLookupState = rolesLookupEntityAdapter.getInitialState({
  pending: [],
  error: null
});
