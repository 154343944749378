import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Organization, OrganizationPermissions, Quota, QuotaType } from '@neuralegion/api';
import { UserOrganizationState } from './organization.state';
import { State } from './state';

export const selectOrganizationState = createFeatureSelector<UserOrganizationState>('organization');

export const selectOrganization = createSelector<State, [UserOrganizationState], Organization>(
  selectOrganizationState,
  (state: UserOrganizationState): Organization => state?.entity
);

export const selectOrganizationPermissions = createSelector<
  State,
  [Organization],
  OrganizationPermissions | undefined
>(
  selectOrganization,
  (organization: Organization): OrganizationPermissions => organization?.permissions
);

export const selectFuzzScanPermission = createSelector<State, [OrganizationPermissions], boolean>(
  selectOrganizationPermissions,
  (permissions: OrganizationPermissions): boolean => permissions?.fuzzer
);

export const selectDastScanPermission = createSelector<State, [OrganizationPermissions], boolean>(
  selectOrganizationPermissions,
  (permissions: OrganizationPermissions): boolean => permissions?.dast
);

export const selectCodeInstrumentationPermission = createSelector<
  State,
  [OrganizationPermissions],
  boolean
>(
  selectOrganizationPermissions,
  (permissions: OrganizationPermissions): boolean => permissions?.repeaters
);

export const selectProjectRepeatersPermission = createSelector<
  State,
  [OrganizationPermissions],
  boolean
>(
  selectOrganizationPermissions,
  (permissions: OrganizationPermissions): boolean => permissions?.projectRepeaters
);

export const selectOrganizationQuotas = createSelector<State, [UserOrganizationState], Quota[]>(
  selectOrganizationState,
  (state: UserOrganizationState) => state.quotas
);

export const selectEnginesQuotaLimit = createSelector<State, [Quota[]], number>(
  selectOrganizationQuotas,
  (quotas: Quota[]): number => quotas?.find((quota) => quota.type === QuotaType.ENGINES)?.limit
);

export const selectOrganizationRequiredSsoProvider = createSelector<State, [Organization], string>(
  selectOrganization,
  (organization: Organization): string => organization?.requiredSsoProvider
);

export const selectOrganizationPendingStatus = createSelector<
  State,
  [UserOrganizationState],
  boolean
>(selectOrganizationState, (state: UserOrganizationState): boolean => state.pending?.length > 0);

export const selectOrganizationAuthorizedTargets = createSelector<State, [Organization], string[]>(
  selectOrganization,
  (organization: Organization): string[] => organization?.authorizedTargets ?? []
);

export const selectOrganizationId = createSelector<State, [Organization], string>(
  selectOrganization,
  (organization: Organization): string => organization.id
);
