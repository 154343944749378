import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PipeTransform,
  TemplateRef
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn
} from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  takeUntil,
  withLatestFrom
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { trackByIdentity } from '@neuralegion/core';
import { extractTouchedChanges } from '../../form';

interface Item {
  id: string;
  label: string;
  disabled: boolean;
  tooltip?: string;
}

export interface ServerFilterableSelectOptions {
  readonly multiple: boolean;
  readonly required: boolean;
  readonly minLength?: number;
  readonly maxLength?: number;
  readonly compact: boolean;
  readonly hintPosition: 'default' | 'above';
  readonly formatPipe: PipeTransform;
  readonly isOptionDisabled: (item: string, selectedItems: string[]) => boolean;
  readonly getOptionTooltip?: (item: string) => string | undefined;
  readonly itemsSortComparator: (a: string, b: string) => number;
  readonly placeholder: string;
  readonly itemName: string;
  readonly tmplLabel: TemplateRef<unknown>;
  readonly tmplItem: TemplateRef<unknown>;
  readonly asyncValidators: AsyncValidatorFn[];
  readonly revalidateTrigger$?: Observable<void>;
  readonly filterFieldValidators: ValidatorFn[];
  readonly selectedOnTop: boolean;
  readonly showEmptyOption: boolean;
  readonly showMultiSelectEmptyOption: boolean;
  readonly tmplOptionsHint: TemplateRef<unknown>;
  readonly tmplFooter: TemplateRef<unknown>;
  readonly tmplItemsFooter: TemplateRef<unknown>;
  readonly matErrorTmpl?: TemplateRef<unknown>;
}

@Component({
  selector: 'share-server-filterable-select',
  templateUrl: './server-filterable-select.component.html',
  styleUrls: ['./server-filterable-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ServerFilterableSelectComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ServerFilterableSelectComponent,
      multi: true
    }
  ]
})
export class ServerFilterableSelectComponent<T extends string>
  implements ControlValueAccessor, OnInit, OnDestroy, Validator
{
  @Input()
  public items$: Observable<T[]>;

  @Input()
  public pending: boolean;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('options')
  set userOptions(value: Partial<ServerFilterableSelectOptions>) {
    this.options = {
      ...this.options,
      ...value
    };
  }

  @Input('aria-label')
  public ariaLabel: string;

  @Output()
  public readonly opened = new EventEmitter<boolean>();

  @Output()
  public readonly queryChanged = new EventEmitter<string>();

  public readonly trackByIdentity = trackByIdentity;

  public readonly textFilter = new FormControl<string>('');
  public readonly selectControl = new FormControl<string | string[]>(null);

  public options: Partial<ServerFilterableSelectOptions> = {
    multiple: true,
    required: false,
    itemName: 'item',
    hintPosition: 'default'
  };

  public itemsList$: Observable<Item[]>;

  private readonly valueSubject = new Subject<string[]>();
  private readonly gc = new Subject<void>();

  private parentControl: AbstractControl;
  private onChange: (ids: string[]) => void;
  private onTouched: () => void;
  private onValidatorChange: () => void;

  private readonly currentSelectedValuesSubject = new BehaviorSubject<string[]>([]);
  private readonly valuesBeforeLastOpenSubject = new BehaviorSubject<string[]>([]);

  get showEmptyOption(): boolean {
    if (this.options.multiple) {
      return this.options.showMultiSelectEmptyOption;
    }
    return this.options.showEmptyOption;
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initItemsList();

    this.selectControl.addAsyncValidators(this.options.asyncValidators);

    this.options.revalidateTrigger$
      ?.pipe(takeUntil(this.gc))
      .subscribe(() => this.selectControl.updateValueAndValidity());

    this.selectControl.statusChanges
      .pipe(distinctUntilChanged(), takeUntil(this.gc))
      .subscribe(() => this.onValidatorChange());

    this.selectControl.valueChanges
      .pipe(
        map((value) => this.asArrayValue(value)),
        withLatestFrom(this.itemsList$),
        takeUntil(this.gc)
      )
      .subscribe(([value, filteredItems]: [string[], Item[]]) => {
        this.preserveSelectedValues(value, filteredItems);
        this.onChange?.(this.currentSelectedValuesSubject.getValue());
      });

    this.valueSubject
      .pipe(
        filter((value: string[]) => !equal(this.asArrayValue(this.selectControl.value), value)),
        takeUntil(this.gc)
      )
      .subscribe((value: string[]) => {
        this.currentSelectedValuesSubject.next(value);
        this.valuesBeforeLastOpenSubject.next(value);

        this.selectControl.setValue(this.asSelectValue(value), { emitEvent: false });
      });

    this.textFilter.valueChanges
      .pipe(
        distinctUntilChanged<string>(equal),
        withLatestFrom(this.opened),
        filter(([_, opened]) => opened),
        map(([filterValue, _]) => filterValue),
        takeUntil(this.gc)
      )
      .subscribe((filterValue) => this.queryChanged.emit(filterValue));

    extractTouchedChanges(this.selectControl)
      .pipe(
        filter((touched) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => this.onTouched());
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public registerOnChange(onChange: (ids: string[]) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }

  public validate(parentControl: AbstractControl): ValidationErrors | null {
    if (!this.parentControl) {
      this.parentControl = parentControl;

      extractTouchedChanges(this.parentControl)
        .pipe(
          filter((touched) => touched),
          distinctUntilChanged(),
          takeUntil(this.gc)
        )
        .subscribe(() => {
          if (!this.selectControl.touched) {
            this.selectControl.markAsTouched();
            this.cdr.detectChanges();
          }
        });
    }

    return this.selectControl.errors;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }

  public writeValue(value: string[] | null): void {
    this.valueSubject.next(value || []);
    this.selectControl.updateValueAndValidity();
  }

  public onOpenedChange(panelOpened: boolean): void {
    if (!panelOpened) {
      this.textFilter.setValue('');
      this.onTouched();
      this.valuesBeforeLastOpenSubject.next(this.asArrayValue(this.selectControl.value));
    }

    this.opened.emit(panelOpened);
  }

  private asArrayValue(value: string[] | string | undefined): string[] {
    return !value ? [] : Array.isArray(value) ? value : [value];
  }

  private asSelectValue(value: string[] | string | undefined): string | string[] {
    if (this.options.multiple) {
      return !value ? [] : Array.isArray(value) ? value : [value];
    }
    return !value ? null : Array.isArray(value) ? value[0] : value;
  }

  private preserveSelectedValues(value: string[], filteredItems: Item[]): void {
    const currentSelectedValue = [...value];

    if (this.textFilter.value?.length > 0) {
      /*
       * If a value that was selected before is deselected and not found in the options,
       * it was deselected due to the filtering, so we restore it
       */
      const isSelectedBefore = (previousValue: string): boolean =>
        !currentSelectedValue.includes(previousValue) &&
        !filteredItems.some((v) => v.id === previousValue);

      this.currentSelectedValuesSubject
        .getValue()
        .filter(isSelectedBefore)
        .forEach((previousValue: string) => currentSelectedValue.push(previousValue));
    }

    this.currentSelectedValuesSubject.next(currentSelectedValue);
    this.selectControl.setValue(this.asSelectValue(currentSelectedValue), { emitEvent: false });
  }

  private initItemsList(): void {
    this.itemsList$ = combineLatest([
      this.items$,
      this.valuesBeforeLastOpenSubject,
      this.currentSelectedValuesSubject
    ]).pipe(
      withLatestFrom(this.textFilter.valueChanges.pipe(startWith(''))),
      map(
        ([[items, valuesBeforeLastOpenRaw, currentSelectedValues], filterValue]: [
          [string[], string[], string[]],
          string
        ]) => {
          const valuesBeforeLastOpen = this.showEmptyOption
            ? valuesBeforeLastOpenRaw.filter((value) => !!value)
            : valuesBeforeLastOpenRaw;
          let itemsList = items;
          if (!filterValue) {
            itemsList = this.sortItemsToShowSelectedFirst(items, valuesBeforeLastOpen);
          }

          return [
            ...(this.showEmptyOption
              ? [{ id: '', label: this.options.placeholder || 'None', disabled: false }]
              : []),
            ...this.mapDataToOptionsItems(itemsList, currentSelectedValues)
          ];
        }
      ),
      takeUntil(this.gc),
      shareReplay(1)
    );
  }

  private mapDataToOptionsItems(items: string[], currentSelectedValues: string[]): Item[] {
    return items.map(
      (item: string): Item => ({
        id: item,
        label: this.options.formatPipe ? this.options.formatPipe.transform(item) : item,
        disabled: this.options.isOptionDisabled
          ? this.options.isOptionDisabled(item, currentSelectedValues)
          : false,
        tooltip: this.options.getOptionTooltip ? this.options.getOptionTooltip(item) : undefined
      })
    );
  }

  private sortItemsToShowSelectedFirst(items: string[], valuesBeforeLastOpen: string[]): string[] {
    const comparator = this.options.itemsSortComparator ?? (() => 0);
    const sortWithComparator = (list: string[]): string[] =>
      [...list].sort((a, b) => comparator(a, b));

    /**
     * Sort items and selected items to have a consistent order in both lists of items
     */
    return [
      ...new Set([...sortWithComparator(valuesBeforeLastOpen), ...sortWithComparator(items)])
    ];
  }
}
