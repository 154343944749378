import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPending, cleanupPendingState } from '@neuralegion/core';
import { loadRoles, loadRolesFail, loadRolesSuccess, resetRoles } from './roles-lookup.actions';
import {
  type RolesLookupState,
  rolesLookupEntityAdapter,
  rolesLookupInitialState
} from './roles-lookup.state';

export const rolesLookupReducer = createReducer<RolesLookupState, Action>(
  rolesLookupInitialState,
  on(
    loadRoles,
    (state: RolesLookupState, action): RolesLookupState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadRolesSuccess,
    (state: RolesLookupState, action): RolesLookupState =>
      rolesLookupEntityAdapter.upsertMany(action.payload, {
        ...state,
        error: null,
        pending: cleanupPending(state.pending, action.parentType, false)
      })
  ),
  on(
    loadRolesFail,
    (state: RolesLookupState, action): RolesLookupState =>
      cleanupPendingState(state, action, action.payload)
  ),
  on(resetRoles, (): RolesLookupState => rolesLookupInitialState)
);
