import { Dictionary } from '@ngrx/entity';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { Role } from '../models';
import { loadRoles } from './roles-lookup.actions';
import { RolesLookupState, rolesLookupEntityAdapter } from './roles-lookup.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;

const selectRolesState = createFeatureSelector<RolesLookupState>('rolesLookup');

export const selectRoleEntities = createSelector<State, [RolesLookupState], Dictionary<Role>>(
  selectRolesState,
  rolesLookupEntityAdapter.getSelectors().selectEntities
);

export const selectRoles = (ids: string[]): MemoizedSelector<State, Role[]> =>
  createSelector<State, [Dictionary<Role>], Role[]>(
    selectRoleEntities,
    (dictionary: Dictionary<Role>) => ids.map((id: string) => dictionary[id]).filter(Boolean)
  );

export const selectRolesMap = (ids: string[]): MemoizedSelector<State, Map<string, Role>> =>
  createSelector<State, [Dictionary<Role>], Map<string, Role>>(
    selectRoleEntities,
    (dictionary: Dictionary<Role>) =>
      ids.reduce((map: Map<string, Role>, id: string) => {
        const role = dictionary[id];
        if (role) {
          map.set(id, role);
        }
        return map;
      }, new Map())
  );

export const selectRole = (id: string): MemoizedSelector<State, Role | undefined> =>
  createSelector<State, [Dictionary<Role>], Role>(
    selectRoleEntities,
    (dictionary: Dictionary<Role>) => dictionary[id]
  );

export const selectRolesLoadingByIdsPendingStatus = createSelector<
  State,
  [RolesLookupState],
  boolean
>(selectRolesState, (state: RolesLookupState) =>
  state.pending.some((action) => action.type === loadRoles.type)
);

export const selectRolesPendingStatus = createSelector<State, [RolesLookupState], boolean>(
  selectRolesState,
  (state: RolesLookupState) => state.pending.length > 0
);
